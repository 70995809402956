.seeker {
    /* display: flex; */
    align-items: center;
    margin-left: 5px;
}

.time {
    font-size: 12px;
    /* padding: 12px; */
}

.track {
    flex: 1 1 auto;
    background-color: #333;
    border-radius: 3px;
    position: relative;
    height: 6px;
    cursor: pointer;
}

.progress {
    background-color: #2e00d4;
    position: absolute;
    width: 0;
    height: 100%;
    border-radius: 3px;
}

.handle {
    position: absolute;
    width: 12px;
    height: 12px;
    background-color: #fff;
    border-radius: 50%;
    left: 0;
    top: -50%;
    margin-left: -6px;
    z-index: 1;
    cursor: pointer;
    opacity: 0;
    box-shadow: 0 0 6px 6px rgba(0, 0, 0, 0.2);
}

.seeker:hover .progress {
    background-color: green;
}

.seeker:hover .handle {
    opacity: 1;
}

.input {
    height: 5px;
}